<template>
  <b-form
      class="employee-form"
      @submit="handleSubmit"
      v-if="settings"
  >
    <b-modal
        id="manager-modal"
        :title="modal.title"
        centered
        title-class="font-22"
        hide-footer
        @show="resetManagerModal"
    >
      <b-form class="employee-form__modal" @submit="handleManagerFormSubmit">
        <b-form-group
            :label="modal.status === 1 ? 'Manager' : 'Subordinate'"
            label-for="manager"
        >
          <!--          <Multiselect-->
          <!--            v-model="manager"-->
          <!--            :options="managers"-->
          <!--            label="name"-->
          <!--            track-by="uid"-->
          <!--          />-->
          <multiselect
              v-model="manager"
              :options="managers"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              placeholder="Pick a value"
              label="name"
              class="managers"
          ></multiselect>
          <!--          <b-form-select-->
          <!--            id="manager"-->
          <!--            v-model="manager"-->
          <!--            :options="managers"-->
          <!--            text-field="name"-->
          <!--            value-field="uid"-->
          <!--            required-->
          <!--          >-->
          <!--            <template #first>-->
          <!--              <b-form-select-option :value="null" disabled>Choose Employee</b-form-select-option>-->
          <!--            </template>-->
          <!--          </b-form-select>-->
        </b-form-group>
        <div class="d-flex justify-content-end">
          <b-button
              type="submit"
              variant="primary"
          >
            Assign
          </b-button>
        </div>
      </b-form>
    </b-modal>
    <b-modal
        id="payment-manager-modal"
        :title="modal.title"
        centered
        title-class="font-22"
        hide-footer
        @show="resetManagerModal"
    >
      <b-form class="employee-form__modal" @submit="handlePaymentManagerFormSubmit">
        <b-form-group
            :label="modal.status === 1 ? 'Manager' : 'Subordinate'"
            label-for="manager"
        >
          <!--          <Multiselect-->
          <!--            v-model="manager"-->
          <!--            :options="managers"-->
          <!--            label="name"-->
          <!--            track-by="uid"-->
          <!--          />-->
          <multiselect
              v-model="manager"
              :options="managers"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              placeholder="Pick a value"
              label="name"
              class="managers"
          ></multiselect>
          <!--          <b-form-select-->
          <!--            id="manager"-->
          <!--            v-model="manager"-->
          <!--            :options="managers"-->
          <!--            text-field="name"-->
          <!--            value-field="uid"-->
          <!--            required-->
          <!--          >-->
          <!--            <template #first>-->
          <!--              <b-form-select-option :value="null" disabled>Choose Employee</b-form-select-option>-->
          <!--            </template>-->
          <!--          </b-form-select>-->
        </b-form-group>
        <div class="d-flex justify-content-end">
          <b-button
              type="submit"
              variant="primary"
          >
            Assign
          </b-button>
        </div>
      </b-form>
    </b-modal>
    <div class="row">
      <div class="col-md-8">
        <AvatarUploader
            v-if="employee"
            :avatar="employee.photo"
            @onChange="handleAvatarChange"
        />
        <b-form-group
            label="Name"
            label-for="name"
        >
          <b-form-input
              id="name"
              v-model="form.name"
              type="text"
          />
        </b-form-group>
        <b-form-group
            label="Email"
            label-for="email"
        >
          <b-form-input
              id="email"
              v-model="form.email"
              type="email"
          />
        </b-form-group>
        <b-form-group
            label="Gender"
            label-for="gender"
        >
          <b-form-select
              id="gender"
              v-model="form.gender"
              :options="genders"
              text-field="title"
              value-field="value"
          ></b-form-select>
        </b-form-group>
        <b-form-group
            label="Phone"
            label-for="phone"
        >
          <b-form-input
              id="phone"
              v-model="form.phone"
              type="text"
          />
        </b-form-group>
        <template>
          <div class="row" v-for="(department,index) in departmentItems" :key="index+'dep'">
            <div class="col-md-5 d-flex justify-content-between">
              <h5 class="department_index">{{ index + 1 }})</h5>
              <b-form-group
                  label="Department"
                  label-for="department"
                  class="department_list"
              >
                <b-form-select
                    id="department"
                    v-model="department.department"
                    @change="getDepartment(department)"
                    :options="settings.departments"
                    text-field="title"
                    value-field="id"
                >
                  <template #first>
                    <b-form-select-option :value="null" disabled>Choose Department</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </div>
            <div class="col-md-5">
              <b-form-group
                  label="Position"
                  label-for="position"
              >
                <Multiselect
                    v-model="department.positions"
                    :options="department.positionList"
                    :multiple="true"
                    :close-on-select="true"
                    :clear-on-select="false"
                    :preserve-search="true"
                    placeholder="Pick some"
                    label="text"
                    track-by="text"
                />
              </b-form-group>
            </div>
            <div class="col-md-2 add_position">
              <button class="btn btn-danger" type="button" v-if="departmentItems.length != 1"
                      @click="removePosition(index)">
                <i class="fas fa-trash"></i>
              </button>
              <button class="btn btn-success ml-2" v-if="departmentItems.length == index+1" type="button"
                      @click="addPosition">
                <i class="fas fa-plus"></i>
              </button>
            </div>
          </div>
          <template v-if="!departmentItems.length">
            <p>Add position</p>
            <button class="btn btn-success mb-2" type="button" @click="addPosition">
              <i class="fas fa-plus"></i>
            </button>
          </template>
          <b-form-group
              label="Category"
              label-for="category"
          >
            <b-form-select
                id="category"
                v-model="form.category"
                :options="settings.categories"
                text-field="title"
                value-field="id"

            >
              <template #first>
                <b-form-select-option :value="null" disabled>Choose Category</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group>
            <b-form-checkbox
                id="checkinable"
                v-model="form.checkinable"
            >
              Check-in enabled
            </b-form-checkbox>
          </b-form-group>
          <b-form-group
              label="Locations"
              label-for="locations"
          >
            <b-form-select
                id="locations"
                v-model="form.location"
                :options="settings.locations"
                text-field="title"
                value-field="id"
            >
              <template #first>
                <b-form-select-option :value="null" disabled>Choose Location</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group
              label="Company"
              label-for="companies"
          >
            <b-form-select
                id="companies"
                v-model="form.company"
                :options="settings.companies"
                text-field="title"
                value-field="id"
            >
              <template #first>
                <b-form-select-option :value="null" disabled>Choose Company</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group
              label="Legal status"
              label-for="legal-status"
          >
            <b-form-select
                id="legal-status"
                v-model="form.legalStatus"
                :options="settings.legalStatuses"
                text-field="title"
                value-field="id"
            >
              <template #first>
                <b-form-select-option :value="null" disabled>Choose Legal status</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group
              label="Payment type"
              label-for="payment-type"
          >
            <b-form-select
                id="payment-type"
                v-model="form.paymentType"
                :options="settings.paymentTypes"
                text-field="title"
                value-field="id"
            >
              <template #first>
                <b-form-select-option :value="null" disabled>Choose Payment Type</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group
              label="Payment scheme"
              label-for="payment-scheme"
          >
            <b-form-select
                id="payment-scheme"
                v-model="form.paymentScheme"
                :options="settings.paymentSchemes"
                text-field="title"
                value-field="id"
            >
              <template #first>
                <b-form-select-option :value="null" disabled>Choose Payment Scheme</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </template>
        <b-form-group
            label="Profile type"
            label-for="profile-type"
        >
          <b-form-select
              id="profile-type"
              v-model="form.profileType"
              :options="[
              { value: 'full', text: 'Full'},
              { value: 'short', text: 'Short'},
            ]"
          />
        </b-form-group>
        <b-form-group
            label="Employee Status"
            label-for="employee-status"
        >
          <b-form-select
              id="employee-status"
              v-model="form.status"
              placeholder="Choose status"
              :options="[
              { value: 'active', text: 'Active'},
              { value: 'inactive', text: 'Inactive'},
            ]"
          />
        </b-form-group>
        <b-form-group>
          <b-form-checkbox
              id="office-key"
              v-model="form.officeKey"
          >
            Office key
          </b-form-checkbox>
        </b-form-group>
        <b-form-group>
          <b-form-checkbox
              id="sso"
              v-model="form.sso"
          >
            SSO enabled
          </b-form-checkbox>
        </b-form-group>
        <div class="d-flex justify-content-between mt-5">
          <div>
            <b-button
                variant="danger"
                @click="onDeleted"
            >
              Delete
            </b-button>
          </div>
          <div>
            <b-button
                type="button"
                variant="secondary"
                class="mr-2"
                @click="backToEmployees"
            >
              Back
            </b-button>
            <b-button
                type="submit"
                variant="primary"
                class="mr-2"
            >
              Save
            </b-button>
          </div>
        </div>
        <!--<b-form-group>
          <b-form-checkbox
                  id="bimicoin"
                  v-model="form.bimicoin"
          >
            Участвовать в BimiCoin
          </b-form-checkbox>
        </b-form-group>-->
      </div>
      <div class="col-md-4">
        <h4>Responsibilities and Duties</h4>
        <ul class="list-unstyled">
          <li
              v-for="(position, idx) in employee.positions"
              :key="idx"
          >
            {{ position.title }}
            <ul
                v-if="position.duties.length"
            >
              <li
                  v-for="duty in position.duties"
                  :key="duty.id"
              >
                {{ duty.value }}
              </li>
            </ul>
          </li>
        </ul>
        <div
            class="mb-4" v-if="employee.category_id === 6"
        >
          <div class="d-flex align-items-center justify-content-between mb-3">
            <h5>Payment Managers</h5>
            <b-button
                variant="primary"
                size="sm"
                @click.prevent="openModal(3)"
            >
              Add
            </b-button>
          </div>
          <div
              v-for="(manager, index) in form.payment_managers"
              :key="manager.uid"
              class="d-flex align-items-center justify-content-between mb-1"
          >
            <span>{{ manager.name }}</span>
            <i
                class="bx bx-trash text-danger font-size-20"
                @click="handleDeletePaymentManager(index)"
            />
          </div>
          <div v-if="form.payment_managers.length === 0">
            <p>Managers not found</p>
          </div>
        </div>
        <div
            class="mb-4"
        >
          <div class="d-flex align-items-center justify-content-between mb-3">
            <h5>Managers</h5>
            <b-button
                variant="primary"
                size="sm"
                @click.prevent="openModal(1)"
            >
              Add
            </b-button>
          </div>
          <div
              v-for="(manager, index) in form.managers"
              :key="manager.uid"
              class="d-flex align-items-center justify-content-between mb-1"
          >
            <span>{{ manager.name }}</span>
            <i
                class="bx bx-trash text-danger font-size-20"
                @click="handleDeleteManager(index, 1)"
            />
          </div>
          <div v-if="form.managers.length === 0">
            <p>Managers not found</p>
          </div>
        </div>
        <div
            class="mb-4"
        >
          <div class="d-flex align-items-center justify-content-between mb-3">
            <h5>Subordinates</h5>
            <b-button
                variant="primary"
                size="sm"
                @click.prevent="openModal(2)"
            >
              Add
            </b-button>
          </div>
          <div
              v-for="(sub, index) in form.subordinates"
              :key="sub.uid"
              class="d-flex align-items-center justify-content-between mb-1"
          >
            <span>{{ sub.name }}</span>
            <i
                class="bx bx-trash text-danger font-size-20"
                @click="handleDeleteManager(index, 2)"
            />
          </div>
          <div v-if="form.subordinates.length === 0">
            <p>Subordinates not found</p>
          </div>
        </div>
        <h5>Individual schedule</h5>
        <table class="schedule__calendar calendar">
          <thead class="calendar__header">
          <tr>
            <th
                v-for="(day, index) in days"
                :key="index"
                :class="{'calendar__header-item_active': selects.some(e => e.index === index + 1)}"
                class="calendar__header-item item"
                @click="toggleHandler(index + 1, defaultTime)"
            >
              {{ day.name }}
            </th>
          </tr>
          </thead>
        </table>
        <div class="btn-group mt-2">
          <button type="button" @click="setDefaultSchedule" class="schedule__save btn btn-primary">Default</button>
          <button type="button" @click="resetSchedule" class="schedule__save btn btn-primary">Reset</button>
          <button type="button" @click="saveSchedule" class="schedule__save btn btn-primary">Save</button>
        </div>
      </div>
    </div>
  </b-form>
</template>

<script>
import isNull from 'lodash/isNull';
import isBoolean from 'lodash/isBoolean';
import startCase from 'lodash/startCase';
import Multiselect from 'vue-multiselect';
import AvatarUploader from '@/components/AvatarUploader';

export default {
  name: 'EmployeeDetails',
  components: {
    Multiselect,
    AvatarUploader
  },
  data() {
    return {
      avatar: null,
      manager: null,
      modal: {
        title: null,
        status: null
      },
      form: {
        name: null,
        email: null,
        phone: null,
        managers: [],
        subordinates: [],
        department: null,
        positions: [],
        category: null,
        location: null,
        company: null,
        legalStatus: null,
        payment_managers: [],
        paymentScheme: null,
        paymentType: null,
        profileType: null,
        officeKey: null,
        sso: null,
        checkinable: null,
        gender: null,
        status: null
      },
      departmentItems: [{department: null, positions: [], positionList: []}],
      days: [
        {name: 'Mon'},
        {name: 'Tue'},
        {name: 'Wed'},
        {name: 'Thu'},
        {name: 'Fri'},
        {name: 'Sat'},
        {name: 'Sun'},
      ],
      genders: [
        {id: 1, title: 'Male', value: 'male'},
        {id: 2, title: 'Female', value: 'female'},
        {id: 3, title: 'Undefined', value: null},
      ],
      selects: [],
      timeZone: 5,
      defaultTime: 12
    }
  },
  async mounted() {
    if (this.settings && this.employee?.uid) {
      await this.constructForm()
      await this.setDepartmentValues()
    }
    this.employee?.schedule?.forEach((e) => {
      let isHasSelectsDay = this.selects.some(e => e.index === e.day_of_week)
      if (!isHasSelectsDay) {
        this.selects.push({
          index: e.day_of_week,
          time: this.calculateTime(e.hour, 5)
        })
      }
    })
  },
  watch: {
    settings(val) {
      if (val && this.employee?.uid) {
        this.constructForm()
        this.setDepartmentValues()
      }
    },
    'settings.positions'() {
      // this.form.positions = this.employee.positions.map(({ id }) => this.settings?.positions.map(({ id, title,department_id }) => ({ value: id, text: title,department:department_id })).find((pos) => pos.value === id));
    },
    departmentItems: {
      handler() {
        let items = [];
        this.departmentItems.forEach(dep => {
          dep.positions.forEach(pos => {
            items.push(pos)
          })
        })
        this.form.positions = items;
      }, deep: true
    },
  },
  computed: {
    employee() {
      return this.$store.getters["employees/employee"];
    },
    employees() {
      return this.$store.state.employees.list;
    },
    managers() {
      return this.settings?.employees?.filter(e => e.status === 'active')
    },
    settings() {
      return this.$store.state.settings
    },
  },
  methods: {
    openModal(status) {
      switch (status) {
        case 1:
          this.modal.status = 1
          this.modal.title = 'Assign Manager To Freelancer'
          this.$bvModal.show('manager-modal')
          break
        case 2:
          this.modal.status = 2
          this.modal.title = 'Add subordinate'
          this.$bvModal.show('manager-modal')
          break
        case 3:
          this.modal.status = 1
          this.modal.title = 'Add payment manager'
          this.$bvModal.show('payment-manager-modal')
          break
      }
    },
    checkDepartment() {
      let check = false;
      let items = [];
      this.departmentItems.forEach(dep => {
        dep.positions.forEach(pos => {
          if (items.some(item => item.department == pos.department && item.value == pos.value)) {
            this.$showErrorToast(`You have chosen same departments with the same positions!`);
            check = true;
          }
          items.push(pos)
        })
      })
      return check;
    },
    getDepartment(department) {
      department.positions = [];
      department.positionList = this.settings?.positions.map(({id, title, department_id}) => ({
        value: id,
        text: title,
        department: department_id
      })).filter(item => item.department == department.department);
    },
    removePosition(index) {
      this.$swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.$delete(this.departmentItems, index)
        }
      })
    },
    addPosition() {
      let val = {department: null, positions: [], positionList: []};
      this.departmentItems.push(val);
    },
    async handleManagerFormSubmit(event) {
      event.preventDefault();
      const fManager = this.settings?.employees.find(({uid}) => uid === this.manager?.uid);
      const obj = {
        manager_uid: this.modal.status === 1 ? fManager.uid : this.$route.params.id,
        subordinate_uid: this.modal.status === 2 ? fManager.uid : this.$route.params.id,
        _method: 'POST'
      }
      if (fManager) {
        await this.$store.dispatch('employees/addManager', this.$convertObjectToFormData(obj)).then(res => {
          if (res) {
            this.$bvModal.hide('manager-modal');
            this.form[this.modal.status === 1 ? 'managers' : 'subordinates'].push({
              uid: this.modal.status === 1 ? obj.manager_uid : obj.subordinate_uid,
              name: this.modal.status === 1 ? fManager.name : this.settings?.employees.find(({uid}) => uid === obj.subordinate_uid).name
            })
            this.manager = null
          }
        })
      }
    },
    async handlePaymentManagerFormSubmit(event) {
      event.preventDefault();
      const fManager = this.settings?.employees.find(({uid}) => uid === this.manager?.uid);
      const obj = {
        manager_uid: this.modal.status === 1 ? fManager.uid : this.$route.params.id,
        subordinate_uid: this.modal.status === 2 ? fManager.uid : this.$route.params.id,
        _method: 'POST'
      }
      if (fManager) {
        await this.$store.dispatch('employees/addPaymentManager', this.$convertObjectToFormData(obj)).then(res => {
          if (res) {
            this.$bvModal.hide('payment-manager-modal');
            this.form['payment_managers'].push({
              uid: this.modal.status === 1 ? obj.manager_uid : obj.subordinate_uid,
              name: this.modal.status === 1 ? fManager.name : this.settings?.employees.find(({uid}) => uid === obj.subordinate_uid).name
            })
            this.manager = null
          }
        })
      }
    },
    async handleDeleteManager(index, status) {
      this.$bvModal.msgBoxConfirm(`Please confirm that you want to delete`, {
        title: 'Please Confirm',
        okVariant: 'danger',
        okTitle: 'Yes',
        cancelTitle: 'No',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
          .then(async (value) => {
            if (value) {
              const obj = {
                managerUid: status === 1 ? this.form.managers[index].uid : this.$route.params.id,
                subordinateUid: status === 2 ? this.form.subordinates[index].uid : this.$route.params.id,
              }
              await this.$store.dispatch('employees/removeManager', {
                managerUid: obj.managerUid,
                subordinateUid: obj.subordinateUid,
                data: this.$convertObjectToFormData({
                  _method: 'DELETE'
                })
              }).then(res => {
                if (res) {
                  this.form[status === 1 ? 'managers' : 'subordinates'].splice(index, 1)
                }
              })
            }
          })
    },
    async handleDeletePaymentManager(index) {
      this.$bvModal.msgBoxConfirm(`Please confirm that you want to delete`, {
        title: 'Please Confirm',
        okVariant: 'danger',
        okTitle: 'Yes',
        cancelTitle: 'No',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
          .then(async (value) => {
            if (value) {
              const obj = {
                manager_uid: this.form.payment_managers[index].uid,
                freelancerUid: this.$route.params.id
              }
              await this.$store.dispatch('employees/removePaymentManager', {
                managerUid: obj.manager_uid,
                freelancerUid: obj.freelancerUid,
                data: this.$convertObjectToFormData({
                  _method: 'DELETE'
                })
              }).then(res => {
                if (res) {
                  this.form['payment_managers'].splice(index, 1)
                }
              })
            }
          })
    },
    resetManagerModal() {
      this.manager = null;
    },
    handleAvatarChange(image) {
      this.avatar = image;
    },
    validateForm() {
      let isValid = true;

      const formEntries = Object.entries(this.form);
      for (let i = 0; i < formEntries.length; i += 1) {
        const [key, value] = formEntries[i];
        if (key !== 'managers' && key !== 'subordinates' && key !== 'payment_managers' && key !== 'gender') {
          if (Array.isArray(value) && value.length === 0) {
            this.$showErrorToast(`${startCase(key)} is required`);
            isValid = false;
            break;
          }

          if (!isBoolean(value) && !value && !(key === 'email' || key === 'phone')) {
            this.$showErrorToast(`${startCase(key)} is required`);
            isValid = false;
            break;
          }

          if (!(key === 'email' || key === 'phone') && this.form.category === 3 && !value && !isBoolean(value)) {
            this.$showErrorToast(`${startCase(key)} is required`);
            isValid = false;
            break;
          }
        }
      }

      return isValid;
    },
    handleSubmit(event) {
      event.preventDefault();
      if (this.validateForm()) {
        const form = {}
        form.name = this.form.name
        if (this.form.phone) {
          form.phone = this.form.phone
        }
        if (this.form.email) {
          form.email = this.form.email
        }
        form.category_id = this.form.category
        form.department_id = this.form.department
        form.location_id = this.form.location
        form.gender = this.form.gender
        form.positions_ids = this.form.positions.map(({value}) => value)
        form.company_id = this.form.company
        form.legal_status_id = this.form.legalStatus
        form.payment_scheme_id = this.form.paymentScheme
        form.payment_type_id = this.form.paymentType
        form.profile_type = this.form.profileType
        form.status = this.form.status
        form.office_key = this.form.officeKey ? 1 : 0
        form.sso_enabled = this.form.sso
        form.checkinable = this.form.checkinable
        form._method = 'PUT'
        if (!this.checkDepartment()) {
          const data = this.$convertObjectToFormData(form);
          this.$store.dispatch('employees/updateEmployee', {
            data,
            id: this.employee.uid,
          });
          if (this.avatar) {
            this.$store.dispatch('employees/uploadEmployeeAvatar', {
              id: this.employee.uid,
              data: this.$convertObjectToFormData({photo: this.avatar})
            });
          }
          if (Number(this.form.category) !== 6) {
            this.form.managers = [];
          }
        }
      }
      this.validateForm();
    },
    backToEmployees() {
      this.$router.push('/employees')
    },
    onDeleted() {
      this.$swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch('employees/deleteEmployee', {
            id: this.$route.params.id,
            data: this.$convertObjectToFormData({_method: 'DELETE'})
          })
              .then((response) => {
                if (response && response.code === 200) {
                  this.$router.push('/employees');
                }
              })
        }
      })
    },
    setDepartmentValues() {
      let result = [];
      const employeePositions = this.employee?.positions ?? []
      const settingPositions = this.settings?.positions ?? []
      let list_positions = employeePositions.map(({id}) => settingPositions.map(({
                                                                                   id,
                                                                                   title,
                                                                                   department_id
                                                                                 }) => ({
        value: id,
        text: title,
        department: department_id
      })).find((pos) => pos.value === id));
      if (list_positions.length > 0) {
        for (let [key, val] of Object.entries(this.groupByKey(list_positions, 'department'))) {
          let positionList = settingPositions.filter(item => item.department === val.department);
          let item = {department: key, positions: val, positionList}
          result.push(item)
        }
      }
      this.departmentItems = result.map(department => {
        department.positionList = settingPositions.map(({id, title, department_id}) => ({
          value: id,
          text: title,
          department: department_id
        })).filter(item => item.department === department.department);
        return department;
      });
    },
    async constructForm() {
      if (this.employee) {
        this.form.name = this.employee.name
        this.form.email = this.employee.email
        this.form.phone = this.employee.phone
        this.form.gender = this.employee?.gender ?? null
        this.form.managers = this.employee?.managers ?? []
        this.form.subordinates = this.employee?.subordinates ?? []
        this.form.department = this.employee.department_id
        this.form.category = this.employee.category_id
        this.form.location = this.employee.location_id
        this.form.company = this.employee.company_id
        this.form.legalStatus = this.employee.legal_status_id
        this.form.payment_managers = this.employee?.payment_managers ?? []
        this.form.paymentScheme = this.employee.payment_scheme_id
        this.form.paymentType = this.employee.payment_type_id
        this.form.profileType = this.employee.profile ? this.employee.profile.type : null
        this.form.status = this.employee?.status ?? null
        this.form.officeKey = this.employee.office_key
        this.form.sso = !isNull(this.employee.user)
        this.form.checkinable = this.employee.checkinable
      }
    },
    calculateTime(hour, timezone) {
      if (hour < Math.abs(timezone) && timezone < 0) {
        return hour + 24 + timezone
      }
      return (hour + timezone) % 24
    },
    calculateDay(day, timezone, hour) {
      if (hour < Math.abs(timezone) && timezone < 0) {
        return day - 1
      }
      if (hour + timezone + 1 > 23) {
        return (day + 1) % 7
      }
      return day
    },
    setDefaultSchedule() {
      this.selects = []
      for (let i = 1; i < 6; i++) {
        this.selects.push({index: i, time: this.defaultTime})
      }
    },
    resetSchedule() {
      this.selects = []
    },
    async saveSchedule() {
      const formData = new FormData()
      formData.append('timezone', this.timeZone)
      const arr = this.selects.sort((a, b) => a.index < b.index ? -1 : 1)
      arr.forEach((e) => {
        formData.append(`schedule[${e.index}][]`, e.time)
      })
      await this.$store.dispatch('employees/updateSchedule', {
        employeeUid: this.employee.uid,
        data: formData
      })
    },
    toggleHandler(index, time) {
      const item = this.selects.findIndex(e => e.index === index)
      if (item > -1) {
        this.selects = this.selects.filter(e => e.index !== index)
      } else {
        this.selects.push({index, time})
      }
    },
  }
}
</script>

<style>
.employee-form i {
  cursor: pointer;
}

.department_index {
  margin-top: 36px;
  margin-right: 10px;
}

.add_position {
  margin-top: 27px;
}

.department_list {
  width: 100%;
}

.managers .multiselect__tags-wrap {
  display: none !important;
}

.managers .multiselect__select {
  z-index: 3;
  top: -3px;
}

.item {
  width: 50px;
  height: 24px;
  border: 1px solid #FFFFFF;
  border-radius: 4px;
  text-align: center;
}

.calendar__header-item {
  background-color: var(--secondary);
  color: var(--light);
  cursor: pointer;
}

.calendar__header-item_active {
  background-color: #00be9b;
}
</style>
